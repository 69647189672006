<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            List User
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-form
                variant="primary"
                @click="handleCreate()"
              >
                <span class="text-nowrap">Tambah User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(no)="data">
          {{ (fetchMeta.current_page - 1) * fetchMeta.per_page + data.index + 1 }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-b-modal.modal-form
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="handleDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ fetchMeta.current_page }} from {{ fetchMeta.last_page }} Pages</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="fetchMeta.total"
              :per-page="page.size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <ModalForm
      :modal-title="modalTitle"
      :form="formData"
      @refresh="getUserList()"
      @success="successToast()"
      @error="errorToast($event)"
    />
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ModalForm from './ModalForm.vue'

export default {
  components: {
    ModalForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
      fetchData: [],
      fetchMeta: {},
      tableColumns: [
        { key: 'no' },
        { key: 'name' },
        { key: 'nim' },
        { key: 'email' },
        { key: 'phone' },
        { key: 'role' },
        { key: 'actions' },
      ],
      page: {
        size: 10,
        number: 1,
      },
      filter: {
        search: '',
      },
      modalTitle: '',
      formData: {},
    }
  },
  watch: {
    currentPage() {
      this.page.number = this.currentPage
      this.getUserList()
    },
    searchQuery() {
      this.filter.search = this.searchQuery
      this.getUserList()
    },
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      const data = {
        params: {
          'page[number]': this.page.number,
          'page[size]': this.page.size,
          'filter[search]': this.filter.search,
        },
      }

      this.$http.get('/v1/user/list', data)
        .then(response => {
          this.setFetchData(response.data.data)
          this.setFetchMeta(response.data.meta)
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    setFetchData(data) {
      this.fetchData = data
    },
    setFetchMeta(data) {
      this.fetchMeta = data
    },
    handleCreate() {
      this.modalTitle = 'Tambah User'
      this.formData = {
        gender: null,
        role: null,
      }
    },
    handleEdit(data) {
      this.modalTitle = 'Edit User'
      this.formData = data
    },
    handleDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$http.delete(`/v1/user/${id}`)
              .then(() => {
                this.successToast()
                this.getUserList()
              })
              .catch(error => {
                this.errorToast(error.response.data.messages)
              })
          }
        })
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
